import React, { useEffect, useRef } from "react";
import { Route, Switch, useHistory, Redirect } from "react-router";
import { makeStyles, Box } from "@material-ui/core";

// Import Component
import Header from "./Component/Header";
import Footer from "./Component/Footer";

// Import Pages
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import ForgotPassword from "./Pages/ForgotPassword";
import GantiPassword from "./Pages/GantiPassword";
import LamaranPekerjaan from "./Pages/LamaranPekerjaan";
import FormulirKerja from "./Pages/FormulirKerja";
import Error404 from "./Pages/404";
import DetailEventRecruitment from "./Pages/DetailEventRecruitment";
import DetailProfile from "./Pages/DetailProfile";
import DetailHasilRecruitment from "./Pages/DetailHasilRecruitment";
import FAQ from "./Pages/FAQ";
import EditProfile from "./Pages/EditProfile";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  content: {
    minHeight: "calc(100% - 28.1vw)",
    paddingTop: "5vw",
    background: "#F3F9FE",
    [theme.breakpoints.down(750)]: {
      paddingTop: "60px",
    },
    
    
  },
}));

const PrivateRoute = ({ ...props }) => {
  if (localStorage.getItem("isLogin")) return <Route {...props} />;
  else {
    localStorage.removeItem("isLogin");
  }
  return <Redirect to="/" />;
};

const PublicRoute = ({ ...props }) => {
  if (!localStorage.getItem("isLogin")) return <Route {...props} />;

  return <Redirect to="/" />;
};

const Routes = () => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  const ref = {
    video: useRef(null),
    nilai: useRef(null),
    program: useRef(null),
    lowongan: useRef(null),
    alur: useRef(null),
  };

  return (
    <Box className={classes.root}>
      <Header innerRef={ref} />
      <Box className={classes.content}>
        <Switch>
          <Route
            exact
            path="/"
            component={(props) => <Home {...props} innerRef={ref} />}
          />
          <PublicRoute path="/login" component={Login} />
          <PublicRoute path="/register" component={Register} />
          <PublicRoute path="/forgot-password" component={ForgotPassword} />
          <Route
            path="/detail-event-recruitment/:eventId"
            component={DetailEventRecruitment}
          />

          <PrivateRoute
            path="/lamaran-pekerjaan"
            component={LamaranPekerjaan}
          />
          {localStorage.getItem("submitValidation") === "Can Apply" && (
            <PrivateRoute
              path="/formulir-kerja/:categoryId"
              component={FormulirKerja}
            />
          )}
          <PrivateRoute path="/ganti-password" component={GantiPassword} />
          <PrivateRoute path="/detail-profile" component={DetailProfile} />
          <PrivateRoute path="/edit-profile" component={EditProfile} />
          <PrivateRoute
            path="/detail-hasil-recruitment/:lamaranId"
            component={DetailHasilRecruitment}
          />
          <Route path="/faq" component={FAQ} />
          <Route component={Error404} />
        </Switch>
      </Box>
      {history.location.pathname !== "/" && <Footer />}
    </Box>
  );
};

export default Routes;
