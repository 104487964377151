import React, { useState } from "react";
import {
  Box,
  Card,
  Grid,
  Typography,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  Button,
  Divider,
  makeStyles,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { AUTHENTICATION } from "../../../service";
import { Toast } from "../../Component/Notification";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5vw 0",
    [theme.breakpoints.down(1025)]: {
      paddingTop: "10vh",
    },
    [theme.breakpoints.down(750)]: {
      paddingTop: "10vh",
    },
    [theme.breakpoints.down(600)]: {
      paddingTop: "4vh",
    },
    [theme.breakpoints.down(500)]: {
      paddingTop: "7vh",
    },
 
    [theme.breakpoints.down(416)]: {
      paddingTop: "6vh",
    },

  },
  content: {
    minHeight: "calc(100% - 35.2vw)",
    paddingTop: "5vw",
    paddingBottom: "5vw",
    background: "#F3F9FE",
    
  },
  cardHeaderRoot: {
    padding: "2.5vw 0 0 0",
    justifyContent: "center",
    display: "grid",
    [theme.breakpoints.down(750)]: {
      padding: "5vw 0 2.5vw 0",
    },
    [theme.breakpoints.down(500)]: {
      padding: "8vw 0 2.5vw 0",
    }
  },
  cardContentRoot: {
    padding: "2.5vw",
    [theme.breakpoints.down(750)]: {
      padding: "0 6vw 6vw",
    }
  },
  cardActionsRoot: {
    padding: "2.5vw",
    justifyContent: "space-between",
  },
  labelSpacing: {
    marginBottom: 3,
  },
  buttonLogin: {
    background: "#E21A43",
    height: 50,
  },
  labelLogin: {
    textTransform: "capitalize",
    color: "white",
  },
  labelCapitalize: {
    fontSize: "0.8vw",
    fontWeight: "bold",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down(750)]: {
      fontSize: "10px",
    }
  },
  inputFilled: {
    background: "#F3F4F5 !important ",
  },
  loginTitle: {
    color: theme.palette.primary.main,
    fontSize: "1.875vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "20px",
    }
  },
  versionContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "5vh",
    
  },
  versionText: {
    color: theme.palette.primary.main,
    fontSize: "0.8vw",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "10px",
    }
  },
  containerLogin: {
    maxWidth: "400px",
    margin: "0 auto",
    padding: "20px",
    [theme.breakpoints.down(750)]: {
      // padding: "20%",
      maxWidth: "100%",
      padding: "0 20vh",
    },
    [theme.breakpoints.down(630)]: {
      // padding: "20%",
      maxWidth: "100%",
      padding: "0 10vh 10vh",
    },
    [theme.breakpoints.down(600)]: {
      // padding: "20%",
      maxWidth: "100%",
      padding: "0 10vh",
    },
    [theme.breakpoints.down(450)]: {
      // padding: "20%",
      maxWidth: "100%",
      padding: "0 5vh",
    },
    [theme.breakpoints.down(450)]: {
      // padding: "20%",
      maxWidth: "100%",
      padding: "5vh 5vh 0",
    }
  }
 
}));

const Login = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [visibility, setVisibility] = useState(true);
  
  function login(e) {
    e.preventDefault();
    localStorage.clear();
    sessionStorage.clear();
    if (username === "") {
      Toast.fire({
        icon: "warning",
        title: "Username Tidak Boleh Kosong",
      });
      return;
    }
    if (password === "") {
      Toast.fire({
        icon: "warning",
        title: "Password Tidak Boleh Kosong",
      });
      return;
    }
    setIsLoading(true);
    var body = {
      username: username,
      password: password,
      roleId: 1,
    };
    AUTHENTICATION.login(body)
      .then((res) => {
        setIsLoading(false);
        if (res.data.auth) {
          localStorage.setItem("token", res.data.auth.authorization);
          localStorage.setItem("tokenType", res.data.auth.tokenType);
          localStorage.setItem("isLogin", true);
          localStorage.setItem("userName", res.data.details.applicantName);
          localStorage.setItem("userEmail", res.data.details.applicantEmail);
          localStorage.setItem("userGender", res.data.details.applicantGender);
          localStorage.setItem("userNik", res.data.details.applicantNik);
          localStorage.setItem(
            "userKtpPath",
            res.data.details.applicantUploadKtpPath
          );
          localStorage.setItem(
            "userBirthPlace",
            res.data.details.applicantBirthPlace
          );
          localStorage.setItem(
            "userBirthDate",
            res.data.details.applicantBirthDate
          );
          localStorage.setItem(
            "userPhoneNumber",
            res.data.details.applicantPhoneNumber
          );
          localStorage.setItem("applicantId", res.data.details.applicantId);
          localStorage.setItem("usersId", res.data.details.users.usersId);
          localStorage.setItem("isEdited", res.data.details.isEdited)
          localStorage.setItem("categoryApplicant","");
          localStorage.setItem("userisSecured", res.data.details.users.isSecured);
        
          if(res.data.details.users.isSecured === false){
            history.push("/ganti-password");
          } else {
            history.push("/");
          }
        } else {
          Toast.fire({
            icon: "warning",
            title: res.data.message,
          });
        }
      })
      .catch((res) => {
        setIsLoading(false);
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.message || "Username / Password Salah",
        });
      });
  }

  return (
    <Box className={classes.root}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid className={classes.containerLogin}>
          <Card>
            <CardHeader
              className={classes.cardHeaderRoot}
              title="Login"
              titleTypographyProps={{
                variant: "h4",
                className: classes.loginTitle,
              }}
            />
            <CardContent className={classes.cardContentRoot}>
              <form onSubmit={login}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.labelSpacing}
                      variant="subtitle1"
                    >
                      Email
                    </Typography>
                    <TextField
                      placeholder="Masukkan email"
                      variant="filled"
                      hiddenLabel
                      size="medium"
                      fullWidth
                      InputProps={{
                        className: classes.inputFilled,
                        disableUnderline: true,
                      }}
                      onChange={(event) => {
                        setUsername(event.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      className={classes.labelSpacing}
                      variant="subtitle1"
                    >
                      Password
                    </Typography>
                    <TextField
                      placeholder="Masukkan password"
                      variant="filled"
                      hiddenLabel
                      size="medium"
                      fullWidth
                      type={visibility ? "password" : "text"}
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                      InputProps={{
                        className: classes.inputFilled,
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setVisibility(!visibility)}
                            >
                              {visibility ? (
                                <Visibility className={classes.icon} />
                              ) : (
                                <VisibilityOff className={classes.icon} />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      className={classes.buttonLogin}
                      variant="contained"
                      disabled={isLoading}
                      fullWidth
                      color="secondary"
                      // onClick={() => {
                      //   login();
                      // }}
                      type="submit"
                    >
                      {isLoading ? (
                        <i className="fas fa-circle-notch fa-spin" />
                      ) : (
                        ""
                      )}
                      <Typography className={classes.labelLogin}>
                        Login
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
            <Divider />
            <CardActions className={classes.cardActionsRoot}>
              <Button
                type="text"
                className={classes.labelCapitalize}
                onClick={() => history.push("/register")}
              >
                Belum daftar?
              </Button>
              <Button
                className={classes.labelCapitalize}
                onClick={() => history.push("/forgot-password")}
              >
                Lupa password?
              </Button>
            </CardActions>
            <Box className={classes.versionContainer}>
              <Typography className={classes.versionText}>v.0.2.17</Typography>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Login;
