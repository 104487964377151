import React, { useEffect, useState } from "react";
import { makeStyles, Box, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { API } from "../../../service/axios";
import { Toast } from "../../Component/Notification";

const useStyles = makeStyles((theme) => ({
  top: {
    background: theme.palette.primary.main,
    color: "#fff",
  },
  footerLeft: {
    position: "absolute",
    left: 0,
    height: "24.6vw",
  },
  footerRight: {
    position: "absolute",
    right: 0,
    height: "24.6vw",
  },
  topPadding: {
    padding: "7vw 14vw",
  },
  logoWhite: {
    width: "90%",
  },
  footerTitle: {
    fontSize: "1vw",
    fontWeight: "bold",
    marginBottom: "1vw",
  },
  footerContent: {
    fontSize: "0.8vw",
    textAlign: "justify",
  },
  footerContentAction: {
    fontSize: "1vw",
    textDecoration: "underline",
    cursor: "pointer",
    fontWeight: "bold",
    [theme.breakpoints.down(750)]: {
      fontSize: "1.5vw",
    },
  },
  bottom: {
    background: theme.palette.primary.dark,
    color: "#fff",
    textAlign: "center",
    fontSize: "0.8vw",
    padding: "1vw 0",
  },

}));

const Footer = () => {
  const classes = useStyles();
  const history = useHistory();
  const [dataFooter, setDataFooter] = useState({});
  const isLogin = localStorage.getItem("isLogin");
  const [message, setMessage] = useState("Success");
  const date = new Date();
  

  useEffect(() => {
    if (isLogin) {
      getFooter("secured");
    } else {
      getFooter("open");
    }
  }, [isLogin]);

  const getFooter = (type) => {
    const url =
      type === "open" ? "footer.getFooterOpen" : "footer.getFooterSecured";

    API(`${url}`)
      .then((res) => {
        setMessage(res.data?.Message);
        if (res.status === 200 && res.data?.Message === "Success" || res.data?.Message === "Not Found") {
          setDataFooter(res.data.Data);
        } else {
          Toast.fire({
            icon: "info",
            title: res?.data?.Message || `Gagal mendapat data footer! url (${url})`,
          });
        }
      })
      .catch((res) => {
        Toast.fire({
          icon: "warning",
          title: res.response?.data?.Message || `Gagal mendapat data footer, url cannot be accessed! ${url}`,
        });
      });
  };

  return (
    <div>
      {message.toLowerCase() !== "not found" && (
        <Box className={classes.footerFix}>
          <Box className={classes.top}>
            <img
              className={classes.footerLeft}
              alt="logo"
              src={process.env.PUBLIC_URL + "/images/footer-left.png"}
            />
            <img
              className={classes.footerRight}
              alt="logo"
              src={process.env.PUBLIC_URL + "/images/footer-right.png"}
            />
            <Grid className={classes.topPadding} container>
              <Grid item xs={2} style={{ zIndex: 1 }}>
                <img
                  className={classes.logoWhite}
                  alt="logo"
                  src={process.env.PUBLIC_URL + "/images/logo-white.png"}
                />
              </Grid>
              <Grid item xs={4} style={{ zIndex: 1, padding: "0 2vw 0 1vw" }}>
                <Typography className={classes.footerTitle}>
                  Tentang Kami
                </Typography>
                <Typography className={classes.footerContent}>
                  {dataFooter.footerSectionAboutUs}
                </Typography>
              </Grid>
              <Grid item xs={3} style={{ zIndex: 1, padding: "0 2vw 0 0" }}>
                <Typography className={classes.footerTitle}>
                  Kontak Kami
                </Typography>
                <Typography className={classes.footerContent}>
                  Telp: {dataFooter.footerSectionTelp}
                </Typography>
                <Typography className={classes.footerContent}>
                  Faks: {dataFooter.footerSectionFax}
                </Typography>
                <Typography className={classes.footerContent}>
                  {dataFooter.footerSectionEmail}
                </Typography>
                <Box style={{ marginTop: "1vw" }}>
                  <Typography className={classes.footerContent}>
                    {dataFooter.footerSectionAddress}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3} style={{ zIndex: 1, padding: "0 2vw 0 0" }}>
                <Typography className={classes.footerTitle}>
                  Butuh Bantuan
                </Typography>
            
                <Typography
                  className={classes.footerContentAction}
                  onClick={() => history.push("/faq")}
                >
                  Frequently Asked Questions
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.bottom}>
            Copyright ©{date.getFullYear()}. BJB Syariah
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Footer;
